import { Col, Input } from 'antd';

import { IEmailField } from './IEmailField';

const EmailField = ({ email, translations, errors, index, handleChangeData }: IEmailField) => (
	<Col xs={12} md={6} lg={6}>
		<label className="required">
			{translations.email}
		</label>
		<Input
			className="input-field"
			name="email"
			value={email}
			onChange={(e) => handleChangeData(e, index || 0)}
			placeholder={translations.email}
			status={errors.email || errors.notValidEmail ? "error": ""}
		/>
		<span className={`error ${errors.email ? 'show' : null}`}>
			{translations.completeCampo}
		</span>
		<span className={`error ${errors.notValidEmail ? "show" : null}`}>
			{translations.completeEmailValido}
		</span>
	</Col>
);

export default EmailField;