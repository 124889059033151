export enum languages {
    resumen= "resumen",
    distribuidor="distribuidor",
    totalProceso="totalProceso",
    totalFirmado="totalFirmado",
    totalDisponible="totalDisponible",
    maximoDisponible="maximoDisponible",
    nombre="nombre",
    estado="estado",
    fecha="fecha",
    formulario= "formulario",
    documentos= "documentos",
    datosInversor= "datosInversor",
	datosFirmantes=  "datosFirmantes",
    datosDistribuidor= "datosDistribuidor",
    formularioInversor= "formularioInversor",
    firmantesAcuerdo= "firmantesAcuerdo",
    contactosInversor= "contactosInversor",
    clasificacionFatca= "clasificacionFatca",
    clasificacionCRS= "clasificacionCRS",
    beneficiarios= "beneficiarios",
    titularFiscal= "titularFiscal",
    documentacionSolicitada= "documentacionSolicitada",
    uploadDocuments= "uploadDocuments",
    estatusInversor= "estatusInversor",
    completarDatos= "completarDatos",
    vehiculoContratar= "vehiculoContratar",
    personasFirmantes= "personasFirmantes",
    tootltipPersonasFirmantes="tootltipPersonasFirmantes",
    tipoPersona= "tipoPersona",
    compromiso= "compromiso",
    nombreInversor= "nombreInversor",
    tooltipNombreInversor="tooltipNombreInversor",
    botonGuardar= "botonGuardar",
    encabezadoFormulario= "encabezadoFormulario",
    formaLegal= "formaLegal",
    tooltipformaLegal= "tooltipformaLegal",
    entityNameTooltip= "entityNameTooltip",
    nombreGestor= "nombreGestor",
    fechaConstitucion= "fechaConstitucion",
    lugarConstitucion= "lugarConstitucion",
    jurisdiccion= "jurisdiccion",
    domicilioSocial= "domicilioSocial",
    leiCode= "leiCode",
    direccionpostal= "direccionpostal",
    paginaWeb= "paginaWeb",
    personaContacto= "personaContacto",
    emailContacto= "emailContacto",
    telefonoContacto= "telefonoContacto",
    registroMercantil= "registroMercantil",
    nif= "nif",
    direccionResidenciaFiscal = "direccionResidenciaFiscal",
    paisResidenciaFiscal= "paisResidenciaFiscal",
    countryTaxResidence= "countryTaxResidence",
    tooltipPaisResidenciaFiscal= "tooltipPaisResidenciaFiscal",
    identificacionFiscal= "identificacionFiscal",
    tooltipIdentificacionFiscal= "tooltipIdentificacionFiscal",
    encabezadoRazon= "encabezadoRazon",
    razonA= "razonA",
    razonB= "razonB",
    razonC= "razonC",
    placeHolderRazon= "placeHolderRazon",
    actividadEconomica= "actividadEconomica",
    territoriosOpera= "territoriosOpera",
    origenFondos= "origenFondos",
    tooltipOrigen= "tooltipOrigen",
    bancoDepositos= "bancoDepositos",
    tooltipbanco= "tooltipbanco",
    direccionBanco= "direccionBanco",
    codigoBic= "codigoBic",
    encabezadoOcde= "encabezadoOcde",
    si= "si",
    no= "no",
    encabezadoSiOcde= "encabeadoSiOcde",
    nombreAutoridad= "nombreAutoridad",
    paisAutoridad= "paisAutoridad",
    paginaWebSi= "paginaWebSi",
    numeroRegistroSi= "numeroRegistroSi",
    encabezadoCotiza= "encabezadoCotiza",
    fechaNacimiento= "fechaNacimiento",
    tooltipFechaNac= "tooltipFechaNac",
    lugarnacimiento= "lugarnacimiento",
    placeholderLugarNac= "placeholderLugarNac",
    nacionalidad= "nacionalidad",
    nationality= "nationality",
    domicilioPrincipal= "domicilioPrincipal",
    mainResidenceCountry= "mainResidenceCountry",
    direccionPostalFisica= "direccionPostalFisica",
    direccionEmailFisica= "direccionEmailFisica",
    numeroTelefonoFisica= "numeroTelefonoFisica",
    pasaporteFisica= "pasaporteFisica",
    nifFisica= "nifFisica",
    ocupacion= "ocupacion",
    nombreEmpleador= "nombreEmpleador",
    fuenteFondos= "fuenteFondos",
    familiarPrp= "familiarPrp",
    tooltipFamiliarPrp= "tooltipFamiliarPrp",
    Prp= "Prp",
    tooltipPrp="tooltipPrp",
    encabezadoFatca= "encabezadoFatca",
    tooltipFacta= "tooltipFacta",
    estadounidense= "estadounidense",
    ibanFisica= "ibanFisica",
    swiftFisica= "swiftFisica",
    nombreFirmante= "nombreFirmante",
    signerNameTooltip= "signerNameTooltip",
    apellidosFirmante= "apellidosFirmante",
    emailFirmante= "emailFirmante",
    masFirmantes= "masFirmantes",
    tituloContacto= "tituloContacto",
    nombreContacto= "nombreContacto",
    apellidosContacto= "apellidosContacto",
    email= "email",
    masContacto= "masContacto",
    fatca1= "fatca1",
    fatca2= "fatca2",
    fatca3= "fatca3",
    fatca4= "fatca4",
    fatca5= "fatca5",
    fatca6= "fatca6",
    fatca7= "fatca7",
    fatca8= "fatca8",
    fatca9= "fatca9",
    tooltipFatca1= "tooltipFatca1",
    tooltipFatca2= "tooltipFatca2",
    tooltipFatca3= "tooltipFatca3",
    tooltipFatca4= "tooltipFatca4",
    tooltipFatca5= "tooltipFatca5",
    tooltipFatca6= "tooltipFatca6",
    tooltipFatca7= "tooltipFatca7",
    tooltipFatca8= "tooltipFatca8",
    encabezadoCRS= "encabezadoCRS",
    tooltipEncabezadoCRS= "tooltipEncabezadoCRS",
    crs1= "crs1",
    crs2= "crs2",
    crs3= "crs3",
    crs4= "crs4",
    crs5= "crs5",
    crs6= "crs6",
    crs7= "crs7",
    enfActiva= "enfActiva",
    tituloExplicacionEnf= "tituloExplicacionEnf",
    entidadVinculada= "entidadVinculada",
    tituloBenerficiarios= "tituloBenerficiarios",
    encabezadoBeneficiarios= "encabezadoBeneficiarios",
    nombreBeneficiario= "nombreBeneficiario",
    fechaYlugarNac= "fechaYlugarNac",
    direccionDomicilio= "direccionDomicilio",
    identificacionFiscalBeneficiario= "identificacionFiscalBeneficiario",
    porcentajeParticipacion= "porcentajeParticipacion",
    prpBeneficiario= "prpBeneficiario",
    tooltipPrpBeneficiario="tooltipPrpBeneficiario",
    masBeneficiarios= "masBeneficiarios",
    tituloTitularFiscal= "tituloTitularFiscal",
    encabezadoTitular= "encabezadoTitular",
    primerParrafoTitular= "primerParrafoTitular",
    segundoParrafoTitular= "segundoParrafoTitular",
    nombrePropiedadFiscal= "nombrePropiedadFiscal",
    paisResidenciaFiscalTitular= "paisResidenciaFiscalTitular",
    porcentajepaticipacionTitular= "porcentajepaticipacionTitular",
    masTitular= "masTitular",
    encabezadoEstatus= "encabezadoEstatus",
    tituloStatusUnoJuridica= "tituloStatusUnoJuridica",
    opcionAStatusUno= "opcionAStatusUno",
    opcionBStatusUno= "opcionBStatusUno",
    opcionCStatusUno= "opcionCStatusUno",
    opcionDStatusUno= "opcionDStatusUno",
    opcionEStatusUno= "opcionEStatusUno",
    opcionFStatusUno= "opcionFStatusUno",
    opcionGStatusUno= "opcionGStatusUno",
    opcionHStatusUno= "opcionHStatusUno",
    opcionIStatusUno= "opcionIStatusUno",
    opcionJStatusUno= "opcionJStatusUno",
    opcionKStatusUno= "opcionKStatusUno",
    parrafoStatusUno= "parrafoStatusUno",
    opcionLStatusUno= "opcionLStatusUno",
    opcionLUno= "opcionLUno",
    opcionLDos= "opcionLDos",
    opcionLTres= "opcionLTres",
    opcionMStatusUno= "opcionMStatusUno",
    ningunaAnterior= "ningunaAnterior",
    tituloStatusDosJuridica= "tituloStatusDosJuridica",
    tituloStatusDosFisica= "tituloStatusDosFisica",
    opcionAStatusDos= "opcionAStatusDos",
    opcionBStatusDos= "opcionBStatusDos",
    opcionCStatusDos= "opcionCStatusDos",
    tituloStatusTres= "tituloStatusTres",
    opcionAStatusTres= "opcionAStatusTres",
    opcionBStatusTres= "opcionBStatusTres",
    opcionCStatusTres= "opcionCStatusTres",
    explicacionStatusDos = "explicacionStatusDos",
    explicacionStatusDosFisica = "explicacionStatusDosFisica",
    tituloExplicacion= "tituloExplicacion",
    tituloStatusCuatro= "tituloStatusCuatro",
    tooltipStatusCuatro="tooltipStatusCuatro",
    tituloStatusCinco= "tituloStatusCinco",
    parrafoFinal= "parrafoFinal",
    cargando = "cargando",
    enviarContrato = "enviarContrato",
    vehiculo= "vehiculo",
    template = "template",
    limiteExcedido = "limiteExcedido",
    limiteExcedidoTitulo = "limiteExcedidoTitulo",
    suitabilityTest = 'suitabilityTest',
    sendContractToApproval = 'sendContractToApproval',
    datosLaborales = 'employmentData',
    upto30 = 'upto30',
    upto60 = 'upto60',
    upto100 = 'upto100',
    upto300 = 'upto300',
    more300 = 'more300',
    fundSources = 'fundSources',
    role= 'role',
    other = 'other',
    anualIncome = 'anualIncome',
    netWorth = 'netWorth',
    expirationDate ='Expiration Date',
    employmentSituation = 'Employment Situation',
    freelance = 'Freelance',
    employee = 'Employee',
    retired = 'Retired',
    unemployed = 'Unemployed',
    clausulaProteccion = 'clausulaProteccion',
    clausulaProteccionTitulo = 'clausulaProteccionTitulo',
    suitability_text1 = 'suitability_text1',
    suitability_title1 = 'suitability_title1',
    suitability_p1 = 'suitability_p1',
    suitability_p2 = 'suitability_p2',
    suitability_p3 = 'suitability_p3',
    suitability_p4 = 'suitability_p4',
    suitability_q1 = 'suitability_q1',
    suitability_q1_01 = 'suitability_q1_01',
    suitability_q1_02 = 'suitability_q1_02',
    suitability_q1_03 = 'suitability_q1_03',
    suitability_q1_04 = 'suitability_q1_04',
    suitability_q1_05 = 'suitability_q1_05',
    suitability_q2 = 'suitability_q2',
    suitability_q2_01 = 'suitability_q2_01',
    suitability_q2_02 = 'suitability_q2_02',
    suitability_q2_03 = 'suitability_q2_03',
    suitability_q3 = 'suitability_q3',
    true = 'true',
    false = 'false',
    dontKnowAnswer = 'dontKnowAnswer',
    suitability_q4 = 'suitability_q4',
    suitability_q4_02 = 'suitability_q4_02',
    suitability_q4_03 = 'suitability_q4_03',
    suitability_q4_04 = 'suitability_q4_04',
    suitability_q5 = 'suitability_q5',
    suitability_q5_02 = 'suitability_q5_02',
    suitability_q5_03 = 'suitability_q5_03',
    suitability_q5_04 = 'suitability_q5_04',
    suitability_q6 = 'suitability_q6',
    suitability_q7 = 'suitability_q7',
    suitability_q8 = 'suitability_q8',
    instrument = 'instrument',
    volumeOp = 'volumeOp',
    qOps = 'qOps',
    vOps = 'vOps',
    suitability_q8_01 = 'suitability_q8_01',
    suitability_q8_02 = 'suitability_q8_02',
    suitability_q8_03 = 'suitability_q8_03',
    suitability_q8_04 = 'suitability_q8_04',
    suitability_q8_05 = 'suitability_q8_05',
    suitability_q8_06 = 'suitability_q8_06',
    suitability_q8_07 = 'suitability_q8_07',
    suitability_q9 = 'suitability_q9',
    suitability_q9_01 = 'suitability_q9_01',
    suitability_q9_02 = 'suitability_q9_02',
    suitability_q9_03 = 'suitability_q9_03',
    suitability_q9_04 = 'suitability_q9_04',
    suitability_q8_moreThan2 = 'suitability_q8_moreThan2',
    suitability_q8_lessThan2 = 'suitability_q8_lessThan2',
    suitability_q8_none = 'suitability_q8_none',
    suitability_q8_lessThan25k = 'suitability_q8_lessThan25k',
    suitability_q8_lessThan150k = 'suitability_q8_lessThan150k',
    suitability_q8_lessThan500k = 'suitability_q8_lessThan500k',
    suitability_q8_moreThan500k = 'suitability_q8_moreThan500k',
    cancel= 'cancel',
    continue= 'continue',
    suitabilityResult= 'suitabilityResult',
    type= 'type',
    representationType = 'representationType',
    representative = 'representative',
    attorney = 'attorney',
    documentType = 'documentType',
    passport = 'passport',
    residentCard = 'residentCard',
    isSignatory = 'isSignatory',
    isRepresentativePRP5 = 'isRepresentativePRP5',
    completeNamePRP51 = 'completeNamePRP51',
    completeNamePRP52 = 'completeNamePRP52',
    positionPRP5 = 'positionPRP5',
    representativeAttorneyData = 'representativeAttorneyData',
    representativeData = 'representativeData',
    representativeAttorney = 'representativeAttorney',
    sole  = 'sole',
    joint = 'joint',
    solidary = 'solidary',
    docNumber = 'docNumber',
	vehicle = 'vehicle'
}