import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";

import { Utils } from "../Utils";

import { IUseDistributorData } from "./IUseDistributorData";
import { DistributorData } from "../Models/DistributorData";

export const useDistributorData = ({
  translations,
  distributorsAPI,
  unlockNextStep,
}: IUseDistributorData) => {
  const [searchParams, _] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [distributorData, setDistributorData] = useState<DistributorData>({
    idContract: 0,
    name: "",
    lastName: "",
    email: "",
    type: "distributor",
    phonePrefix: "",
    phoneNumber: "",
	emailToNotify: ""
  });
  const [errors, setErrors] = useState<any>({
    name: false,
    lastName: false,
    email: false,
    phonePrefix: false,
    phoneNumber: false,
  });

  useEffect(() => {
    if (searchParams.has("ContractId")) {
      configureInitialData(Number(searchParams.get("ContractId")));
    }
  }, [translations]);

  const handleChangeData = (e: any) => {
    const { name, value } = e.target;
    if (!!errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: false,
      }));
    }
	let dist = { ...(distributorData as any) };
	if(name === "email"){
		dist.emailToNotify = value;
	}
    dist[name] = value;
    setDistributorData(dist);
  };

  const configureInitialData = async (ContractId: number) => {
    setLoading(true);
    await distributorsAPI
      .getDistributors(ContractId)
      .then((resp) => {
        if (resp.success && resp.data) {
			setDistributorData(resp.data);
        }else{
			setDistributorData({ ...distributorData, idContract: ContractId });
		}
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateData = (data: any) => {
    const { name, lastName, email, phoneNumber, phonePrefix, emailToNotify } = data;
    const updatedErrors: any = {
      name: !name,
      lastName: !lastName,
      email: !email || !Utils.validate("email", email),
	  phonePrefix: !phonePrefix,
	  phoneNumber: !phoneNumber,
    };

    setErrors((prevState: any) => ({ ...prevState, ...updatedErrors }));
	let notifEmail = validateEmails(emailToNotify.replace(/ /g, ""));
	let allErrors = Object.values(updatedErrors).some(Boolean);
	return (!allErrors && notifEmail);
  };

  const validateEmails = (email: string) => {
	const emails = email.split(";");
	if(emails.length === 1 && emails[0] === ""){
		return true;
	}
	const invalidEmails = emails.filter((e) => !Utils.validate("email", e));
	if (invalidEmails.length > 0) {
		message.error(translations.invalidEmails);
		return false;
	}
	return true;
  }

  const saveData = async () => {
    setLoading(true);
    if (validateData(distributorData)) {
	  distributorData.emailToNotify = distributorData.emailToNotify.replace(/ /g, "");
      if (distributorData.id === 0 || !distributorData.id) {
        /* POST */
        await distributorsAPI
          .saveDistributors(distributorData)
          .then((resp) => {
            if (resp.success) {
              message.success(translations.datosGuardados);
              unlockNextStep();
            } else {
              message.error(translations.messageError);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
		  setLoading(false);
      } else {
        /* PUT */
        await distributorsAPI
          .updateDistributors(distributorData)
          .then((resp) => {
            if (resp.success) {
              message.success(translations.datosGuardados);
              unlockNextStep();
            } else {
              message.error(translations.messageError);
            }
          });
      }
    } 
	setLoading(false);
  };

  return {
    loading,
    errors,
    distributorData,
    handleChangeData,
    saveData,
  };
};
