import { Col, DatePicker } from "antd";
import moment from "moment";

import { IDocumentExpirationField } from "./IDocumentExpirationField";

const DocumentExpirationField = ({ documentExpiration, translations, errors, index, handleSelectOrDatePicker }: IDocumentExpirationField) => (
	<Col xs={24} md={6} lg={6} className="titles">
		<label className="required"> {translations.expirationDate}</label>
		<DatePicker
			allowClear={false}
			className="input-field"
			name="documentExpiration"
			value={documentExpiration ? moment(documentExpiration) : undefined}
			format="DD/MM/YYYY"
			onChange={ handleSelectOrDatePicker("documentExpiration", index || 0) }
			status={errors.documentExpiration || errors.notValidExpirationDate ? 'error' : ''}
		/>
		<span className={`error ${errors.documentExpiration ? 'show' : null}`}>{translations.completeCampo}</span>
		<span className={`error ${errors.notValidExpirationDate ? 'show' : null}`}>{translations.invalidDate}</span>
	</Col>
);

export default DocumentExpirationField;