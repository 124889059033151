export class Utils {
  public static fieldRegex = {
    email: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/),
    document: new RegExp(/^[A-Z0-9]*$/),
    name: new RegExp(/^[A-Z][a-zÀ-ÖØ-öø-ÿ]*(?:['-][A-Z][a-zÀ-ÖØ-öø-ÿ]*)*(?:\s[A-Z][a-zÀ-ÖØ-öø-ÿ]*(?:['-][A-Z][a-zÀ-ÖØ-öø-ÿ]*)*)*(?:\s[A-Z]+)*$/),
  };

  public static validate = (field: string, value: string) => {
    return Utils.fieldRegex[field as keyof typeof Utils.fieldRegex].test(value);
  };
} 