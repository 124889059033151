import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import { Utils } from "../Utils";
import { IUseRepresentativesForm } from "./IUseRepresentativesForm";
import { Representative } from "../Models/Representative";
import { emptyRepresentative, emptyRepresentativeErrors } from "../Assets/Data/EmptyValues";
import { InvestorDTO } from "../Models/InvestorDTO";

export const useRepresentativesForm = ({
	translations,
	contractAPI,
	representativesAPI,
	unlockNextStep,
	investorAPI
}: IUseRepresentativesForm) => {
	const [searchParams, _] = useSearchParams();

	const [representatives, setRepresentatives] = useState<Representative[]>([{...emptyRepresentative}]);
	const [loading, setLoading] = useState<boolean>(false);
	const [contractId, setContractId] = useState<number>(0);
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([])
	const [errors, setErrors] = useState<any[]>([{...emptyRepresentativeErrors}]);
	const [noRepresentativesError, setNoRepresentativesError] = useState<boolean>(false);

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			getNationalities();
			setContractId(Number(searchParams.get('ContractId')))
		}
	}, [searchParams.has('ContractId')]);

	const getNationalities = () => {
		investorAPI.getCountries().then((res: any) => {
			if (res.length > 0){
				setNationalitiesList(res);
			}
		})
	};

	const addRow = () => {
		let newElement: Representative = {...emptyRepresentative}
		setRepresentatives(oldArray => [...oldArray, newElement]);
		setErrors(prevState => [...prevState, {...emptyRepresentativeErrors}]);
	}

	const handleChangeData = (value: any, index: number, field: string) => {
		const newErrors = [...errors];
		newErrors[index][field] = false;
		setErrors(newErrors);

		let array = [...representatives as any];
		array[index][field] = value;
		setRepresentatives(array);
	};

	const handleChangePRPData = (e: any) => {
		const { name, value } = e.target;
		const newErrors = [...errors];
		newErrors[0][name] = false;
		setErrors(newErrors);

		let array = [...representatives as any];
		array[0][name] = value;
		setRepresentatives(array)
	};

	const handleSelectData = (field: string,  index: number) => (value: string) => {
		const newErrors = [...errors];
		newErrors[index][field] = false;
		setErrors(newErrors);

		let array = [...representatives as any];
		array[index][field] = value;
		setRepresentatives(array);
	};

	const configureInitialData = async(ContractId: number) => {
			setLoading(true);
			await representativesAPI.getLegalRepresentatives(ContractId).then(async (res: any) => {
				if (res.success) {
					if(res.data.length > 0 ) {
						setErrors(res.data.map(() => ({...emptyRepresentativeErrors})));
						setRepresentatives(res.data)
					}else{
						await getInvestors(ContractId)
					}
				}
			});
			setLoading(false);
	};
	
	const getInvestors = async(contractId: number) => {
		await investorAPI.getInvestors(contractId).then((res: any) => {
			if (res.success) {
				if(res.data.length > 0 ) {
					let newRepresentatives = res.data.map((x: InvestorDTO) => {
						let docType; 
						if(x.nif) docType = "NIF";
						if(x.dni) docType = "DNI";
						if(x.passport) docType = "Pasaporte";
						if(x.residenceCard) docType = "Tarjeta de residencia";
						return {
							...emptyRepresentative,
							documentType: docType,
							documentNumber: x.dni || x.passport || x.residenceCard || x.nif,
							documentExpiration: x.docValidThru,
							name: x.name,
							lastName: x.lastName,
							nationality: x.nationality,
							email: x.emailContact,
							birthDate: x.birthDate,
							idContract: x.idContract,
							isSignatory: true,
						}
					}
					);
					setRepresentatives(newRepresentatives);
					setErrors(newRepresentatives.map(() => ({...emptyRepresentativeErrors})));
				}
			}
			}
		);	
	}
	
	const deleteRow = async (i: number) => {
		if(representatives.length > 1){
			if(representatives[i].id > 0){
				await representativesAPI.deleteLegalRepresentative(representatives[i].id)
					.then((res: any) => {
						if(res.success){
							setRepresentatives((prevState: any) => [...prevState.filter((_: any, index: number) => index !== i)]);
							setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
						}else{
							message.error(translations.messageError);
						}
					});
			}else{
				setRepresentatives(prevState => [...prevState.filter((_, index) => index !== i)]);
				setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
			}
		}else{
			message.error(translations.apoderadoMinimo);
		}
	};

	const validateRepresentatives = (data: Representative, index: number) => {
		let isOK = true;
		const newErrors = [...errors];
		if (!data.documentType) {
			newErrors[index].documentType = true;
			isOK = false;
		}
		if (!data.documentNumber) {
			newErrors[index].documentNumber = true;
			isOK = false;
		} else if (!/^[a-zA-Z0-9]*$/g.test(data.documentNumber!)) {
			newErrors[index].documentFormat = true;
			isOK = false;
		}
		if (!data.name) {
			newErrors[index].name = true;
			isOK = false;
		}
		if (!data.lastName) {
			newErrors[index].lastName = true;
			isOK = false;
		}
		if (!data.nationality) {
			newErrors[index].nationality = true;
			isOK = false;
		}
		if (!data.email || !Utils.validate("email", data.email)) {
			newErrors[index].email = true;
			isOK = false;
		}
		if (!data.documentExpiration) {
			newErrors[index].documentExpiration = true;
			isOK = false;
		} else if (moment(data.documentExpiration).isBefore(moment())) {
			newErrors[index].documentExpirationInvalid = true;
			isOK = false;
		}
		if (!data.birthDate) {
			newErrors[index].birthDate = true;
			isOK = false;
		}
		if (!data.administrator) {
			newErrors[index].administrator = true;
			isOK = false;
		}
		if (!data.administratorType) {
			newErrors[index].administratorType = true;
			isOK = false;
		}
		if (data.prP5) {
			if (!data.namePRP51) {
				newErrors[index].namePRP51 = true;
				isOK = false;
			}
			if (!data.positionPRP51) {
				newErrors[index].positionPRP51 = true;
				isOK = false;
			}
		}
		setErrors(newErrors);
		return isOK;
	};

	const postRepresentatives = async (newRepresentatives: Representative[]) => {
		await representativesAPI.saveLegalRepresentatives(newRepresentatives)
		.then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
				unlockNextStep();
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const updateRepresentatives = async (updatedRepresentatives: Representative[]) => {
		await representativesAPI.updateLegalRepresentatives(updatedRepresentatives)
		.then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const saveData = async() => {
		if (representatives.length > 0) {
			setLoading(true);
			let isDataOK = true;
			representatives.map((representative: Representative, i) => {
				const isDataValid = validateRepresentatives(representative, i);
				if (!isDataValid) {
					isDataOK = false;
				} else {
					representative.birthDate = moment(representative.birthDate).format('YYYY-MM-DD');
					representative.documentExpiration = moment(representative.documentExpiration).format('YYYY-MM-DD');
					representative.idContract = contractId;
				}
				return representative;
			});
			let newRepresentatives = representatives.filter((x: any) => !x.id);
			let updatedRepresentatives = representatives.filter((x: any) => x.id > 0);

			if (isDataOK) {
				if (newRepresentatives.length > 0) {
					await postRepresentatives(newRepresentatives);
				}
				if(updatedRepresentatives.length > 0){
					await updateRepresentatives(updatedRepresentatives);
				}
			} 
		} else {
			setNoRepresentativesError(true);
		}
		setLoading(false);
	};

	const handleChangeDate = (date: any, field: string, index: number) => {
		const newErrors = [...errors];
		newErrors[index][field] = false;
		setErrors(newErrors);

		let array = [...representatives as any];
		array[index][field] = date;
		setRepresentatives(array);
	};

	return {
		loading,
		noRepresentativesError,
		errors,
		nationalitiesList,
		addRow,
		handleChangeData,
		deleteRow,
		handleSelectData,
		handleChangeDate,
		handleChangePRPData,
		saveData,
		representatives
	};
};