import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import { Utils } from "../Utils";
import { Contact } from "../Models/ContactData";
import { InvestorDTO } from "../Models/InvestorDTO";

export const useContacts = ({
	translations,
	investorAPI,
	contactAPI,
	unlockNextStep,
	distributorsAPI,
}: any) => {
	const [searchParams, _] = useSearchParams();

	const [contacts, setContacts] = useState<Contact[]>([]);
	const [oldContacts, setOldContacts] = useState<InvestorDTO[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [contractId, setContractId] = useState<number>(0);
	const [errors, setErrors] = useState<any[]>([]);
	
	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			setContractId(Number(searchParams.get('ContractId')))
		}
	}, [translations]);

	const configureInitialData = async(contractId: number) => {
		setLoading(true);
		let initialData: InvestorDTO[] = [];
		await investorAPI.getInvestors(contractId).then((res: any) => {
			if (res.success) {
				res.data.map((investor: any) => {
					investor.type = 'inversor';
					initialData.push(investor);
				})
			}
		})
		await distributorsAPI.getDistributors(contractId).then((res: any) => {
			if (res.success) {
				if(res.data){
					res.data.type = 'distribuidor';
					initialData.push(res.data);
				}
			}
		})
		setOldContacts(initialData);
		await contactAPI.getAllContacts(contractId).then((res: any) => {
			if (res.success) {
				setContacts(res.data);
				setErrors(res.data.map(() => ({
					nameContact: false,
					lastNameContact: false,
					email: false,
					phone: false,
					docType: false,
					docNumber: false,
					docExpirationDate: false,
					birthdate: false,
				})));
			}
			setLoading(false);
		})
		.catch((err: any) => {
			console.log(err);
		});
		setLoading(false);
	}

	const addRow = () => {
		let el: Contact = {
			id: 0,
			name: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			phonePrefix: "",
			idContract: contractId
		};
		setContacts((oldArray:any) => [...oldArray, el]);
		setErrors(prevState => [...prevState, {
			name: false,
			lastName: false,
			email: false,
			phoneNumber: false,
			phonePrefix: false,
		}]);
	};

	const handleChangeData = (e: any, index: number) => {
		const { name, value } = e.target;
		const newErrors = [...errors];
		newErrors[index][name] = false;
		setErrors(newErrors);

		let array = [...contacts as any];
		array[index][name] = value;
		setContacts(array)
	};
	
	const deleteRow = (i: number) => {
		if(contacts[i].id !== 0){
			contactAPI.deleteContact(contacts[i].id)
			.then((res: any) => {
				if(res.success){
					setContacts(prevState => [...prevState.filter((_, index) => index !== i)]);
					setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
					message.success(translations.datosGuardados);
				}
			}); 
		}else{
			setContacts(prevState => [...prevState.filter((_, index) => index !== i)]);
			setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
		}
	};

	const validateData = (data: Contact, index: number) => {
		let isOK = true;
		const newErrors = [...errors];
		if (!data.name) {
			newErrors[index].name = true;
			isOK = false;
		}
		if (!data.lastName) {
			newErrors[index].lastName = true;
			isOK = false;
		}
		if (!data.email || !Utils.validate("email", data.email)) {
			newErrors[index].email = true;
			isOK = false;
		}
		if (!data.phonePrefix) {
			newErrors[index].phonePrefix = true;
			isOK = false;
		}
		if (!data.phoneNumber) {
			newErrors[index].phoneNumber = true;
			isOK = false;
		}

		setErrors(newErrors);
		return isOK;
	};

	const saveData = async() => {
		setLoading(true);
		setErrors([]);
		let isDataOK = true;
		contacts.map((contact: Contact, i) => {
			if (!validateData(contact, i)) {
				isDataOK = false;
			}
		});
		if (isDataOK) {
			let newContacts = contacts.filter((contact: Contact) => contact.id === 0);
			let updatedContacts = contacts.filter((contact: Contact) => contact.id !== 0);
			if(newContacts.length > 0){
				await postContacts(newContacts);
			}
			if(updateContacts.length > 0){
				await updateContacts(updatedContacts);
			}
			
		} else {
			setLoading(false);
		}
	};
	
	const updateContacts = async(data: any) => {
		await contactAPI.updateContacts(data).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
			}else{
				message.error(translations.messageError);
			}
			setLoading(false);
		})
		.catch((err: any) => {
			setLoading(false);
			message.error(translations.messageError);
		}
	)}

	const postContacts = async(data: any) => {
		await contactAPI.saveContacts(data).then((res: any) => {
			if(res.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
			}else{
				message.error(translations.messageError);
			}
			setLoading(false);
		})
		.catch((err: any) => {
			setLoading(false);
			message.error(translations.messageError);
		}
	)}


	const handleChangeDate = (date: any, field: string, index: number) => {
		const newErrors = [...errors];
		newErrors[index][field] = false;
		setErrors(newErrors);

		let array = [...contacts as any];
		array[index][field] = date;
		setContacts(array);
	};

	return {
		loading,
		errors,
		contacts,
		addRow,
		handleChangeData,
		deleteRow,
		handleChangeDate,
		saveData,
		oldContacts
	};
};