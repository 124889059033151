import { Col, Select } from "antd";

import { ICountryField } from "./ICountryField";

const { Option } = Select;

const CountryField = ({
	value,
	fieldName,
	nationalitiesList,
	translations,
	errors,
	index,
	handleSelectOrDatePicker
}: ICountryField) => (
	<Col xs={24} md={6} lg={6} className="titles">
		<label className="required"> {translations[fieldName]}</label>
		<Select
			className="input-field"
			value={value}
			onChange={handleSelectOrDatePicker(fieldName, index)}
			status={errors[index][fieldName] ? 'error' : ''}
			placeholder="Seleccione una opción"
			showSearch
			filterOption={(input, option) =>
				(option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
			}
		>
			{nationalitiesList.map((nationality: any)=> {
				return <Option value={nationality.name_lang2}>{nationality.name_lang2}</Option>
			})}
		</Select>
		<span className={`error ${errors[index][fieldName] ? 'show' : null}`}>{translations.completeCampo}</span>
	</Col>
);

export default CountryField;