import { Col, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { INameField } from "./INameField";

const NameField = ({ name, translations, index, errors, handleChangeData }: INameField) => (
	<Col xs={12} md={6} lg={6}>
		<label className="required">
			{translations.nombreFirmante}
			<Tooltip placement="top" title={<span>{translations.signerNameTooltip}</span>}>
				{" "} <InfoCircleOutlined />
			</Tooltip>
		</label>
		<Input
			className="input-field"
			name="name"
			value={name}
			onChange={(e) => handleChangeData(e, index || 0)}
			placeholder={translations.nombreFirmante}
			status={ errors.name || errors.invalidNameFormat? "error" : "" }
		/>
		<span className={`error ${errors.name ? 'show' : null}`}>
			{translations.completeCampo}
		</span>
		<span className={`error ${errors.invalidNameFormat ? 'show' : null}`}>{translations.invalidFormat}</span>
	</Col>
);

export default NameField;