import { Col, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { ILastNameField } from "./ILastNameField";

const LastNameField = ({ lastName, translations, errors, index, handleChangeData }: ILastNameField) => (
	<Col xs={24} md={12} lg={12}>
		<label className="required">
			{translations.apellidosFirmante}
			<Tooltip placement="top" title={<span>{translations.signerNameTooltip}</span>}>
				{" "} <InfoCircleOutlined />
			</Tooltip>
		</label>
		<Input
			className="input-field"
			name="lastName"
			value={lastName}
			onChange={ (e) => handleChangeData(e, index || 0) }
			placeholder={translations.apellidosFirmante}
			status={ errors.lastName || errors.invalidLastNameFormat ? "error" : ""}
		/>
		<span className={`error ${errors.lastName ? 'show' : null}`}>
			{translations.completeCampo}
		</span>
		<span className={`error ${errors.invalidLastNameFormat ? 'show' : null}`}>
			{translations.invalidFormat}
		</span>
	</Col>
);

export default LastNameField;