import { Col, Select } from "antd";
import { IFundsSourceField } from "./IFundsSourceField";

const { Option } = Select;

export const sourceOptionsLanguages = [
	{ es: 'Salario', en: 'Salary' },
	{ es: 'Actividad empresarial', en: 'Business Activity' },
	{ es: 'Venta de bienes', en: 'Sale of Goods' },
	{ es: 'Indemnización', en: 'Compensation' },
	{ es: 'Donación', en: 'Donation' },
	{ es: 'Herencia', en: 'Inheritance' },
	{ es: 'Ahorros', en: 'Savings-Economy' }
];

const FundsSourceField = ({
	value,
	fieldName,
	index,
	translations,
	contractLanguage,
	errors,
	handleSelectOrDatePicker
}: IFundsSourceField) => (
	<Col xs={24} md={6} lg={6} className="titles">
		<label className="required"> {translations.fundSources} </label>
		<Select
			className="input-field"
			value={value}
			onChange={handleSelectOrDatePicker(fieldName, index || 0)}
			status={errors[index].fundsSource ? 'error' : ''}
			placeholder="Seleccione una opción"
		>
			{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
			<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
		</Select>
		<span className={`error ${errors[index].source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
	</Col>
);

export default FundsSourceField;